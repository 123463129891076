import React, { FC } from 'react';

import { TrustBox as Atom } from '@components/Atoms';

export const TrustBox: FC<any> = ({
  display,
  heading,
  type,
  headingStyles,
  backgroundColor,
  disableClick,
}) => {
  return <Atom data={{ display, heading, type, headingStyles, backgroundColor, disableClick }} />;
};
