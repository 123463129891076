import React, { FC } from 'react';

import { ChoosePackage as Organism } from '@components/Organisms';

import StyledChoosePackageTopBackground from '@factories/RowFactory/components/ChoosePackage/styled/StyledChoosePackageTopBackground';

import { ChoosePackageT } from './types';
import StyledChoosePackageWrapper from '@factories/RowFactory/components/ChoosePackage/styled/StyledChoosePackageWrapper';
import StyledChoosePackageHalfwayBackground from './styled/StyledChoosePackageHalfwayBackground';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import PreChoosePackageHeader from './PreChoosePackageHeader';

const StyledContainer = styled.div<{ addHalfwayBackground: boolean; hasPreContent: boolean }>`
  position: relative;

  ${({ addHalfwayBackground }) =>
    addHalfwayBackground &&
    `
  @media(${breakpoints.tablet}) {
    padding-top: 20rem;
  }
  `}

  ${({ hasPreContent }) =>
    hasPreContent &&
    `
    margin-top: 4rem;
  `}
`;

export const ChoosePackage: FC<ChoosePackageT> = ({
  products,
  heading,
  subHeading,
  subHeadingLink,
  addressType,
  addTopBackground = false,
  addHalfwayBackground = false,
  bottomLinkText,
  bottomLinkHref,
  bottomCardTitle,
  bottomCardBody,
  bottomCardButton,
  displayBottomCard,
  purchasePage = false,
  addPreChoosePackageHeader,
  preChoosePackageText,
  preChoosePackagePromoCode,
  showBennyAbovePackages,
  exclusion_list,
  propertyTypeFilter,
}) => {
  return (
    <StyledContainer
      id="choose-package-section"
      addHalfwayBackground={addHalfwayBackground}
      hasPreContent={!!(addPreChoosePackageHeader && preChoosePackagePromoCode)}
    >
      {addPreChoosePackageHeader && preChoosePackagePromoCode && (
        <PreChoosePackageHeader
          preChoosePackageText={preChoosePackageText}
          preChoosePackagePromoCode={preChoosePackagePromoCode}
        />
      )}
      {addHalfwayBackground && <StyledChoosePackageHalfwayBackground />}
      <StyledChoosePackageWrapper>
        <Organism
          heading={heading}
          subHeading={subHeading}
          subHeadingLink={subHeadingLink}
          displaySmartWifi
          products={products}
          addressType={addressType}
          bottomLinkText={bottomLinkText}
          bottomLinkHref={bottomLinkHref}
          bottomCardTitle={bottomCardTitle}
          bottomCardBody={bottomCardBody}
          bottomCardButton={bottomCardButton}
          displayBottomCard={displayBottomCard}
          purchasePage={purchasePage}
          showBennyAbovePackages={!!(showBennyAbovePackages && preChoosePackagePromoCode)}
          exclusion_list={exclusion_list}
          propertyTypeFilter={propertyTypeFilter}
        />
      </StyledChoosePackageWrapper>
    </StyledContainer>
  );
};
